<template>
    <Toast/>
    <br>
    <br>
    <p v-if="this.visibleCards.length!==0 && !this.skipped"><b>Swipe</b> each question to left, right or up to answer yes, no or skip respectively (you may also use your arrow keys (left, right, down or spacebar) or just click the buttons). In total, there are 30 questions. The answers to those will make us understand your coaching needs and will ensure an effective matching.</p>
    <p v-if="this.visibleCards.length!==0 && !this.skipped">On average, this takes users 4 minutes and 13 seconds. Can you beat it? <b>
    <vue-countdown :time="(4 * 60 + 13) * 1000" v-slot="{ minutes, seconds }">
      {{ minutes }}:{{ ('00'+seconds).slice(-2) }}.
    </vue-countdown></b>
    </p>
    <p v-else>
    For you, the main areas of growth are {{ topareas }}. Below is a detailed view on your key areas of growth. The higher the score for a given dimension, the more it will be valuable to focus on it with our coaches.
    </p>

    <div class="justify-center q-main" v-if="this.visibleCards.length===0 || this.skipped">
        <div class="card flex flex-column align-items-center" style="width: 100%">
            <Chart type="radar" :data="radarData" :options="radarOptions" style="width: 50%" />
        </div>


        <!-- Restitution -->
        <div class="surface-section">
            <div class="font-medium text-3xl text-900 mb-3">Restitution</div>
            <div class="text-500 mb-5">Here's what your scores mean.</div>
            <ul class="list-none p-0 m-0">
                <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium">Grow</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                        {{ currTexts[0] }}
                    </div>
                </li>
                <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium">Lead</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                        {{ currTexts[1] }}
                    </div>
                </li>
                <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium">Balance</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                        {{ currTexts[2] }}
                    </div>
                </li>
                <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium">Change</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                        {{ currTexts[3] }}
                    </div>
                </li>
                <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium">Escape</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                        {{ currTexts[4] }}
                    </div>
                </li>
                <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium">Reflect</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                        {{ currTexts[5] }}
                    </div>
                </li>
            </ul>
        </div>
        <br>



        <div v-if="external">
            <Button label="You're done! Click here to save." icon="pi pi-check" v-on:click="submit"></Button>
        </div>
        <div v-else>
            <Button label="You're done! Click here to continue to the last step." icon="pi pi-check" v-on:click="submit"></Button>
        </div>
    </div>
    <div v-else>
        <div 
            ref="cardsmain"
            class="flex justify-center cards-main"
            style="position:relative;"
        >
            <!--@touchstart="disableOverflow"
                @touchend="enableOverflow"-->
            <C_QCardsStack
                ref="cardstack"
                :cards="visibleCards"
                @cardAccepted="handleCardAccepted"
                @cardRejected="handleCardRejected"
                @cardSkipped="handleCardSkipped"
                @hideCard="removeCardFromDeck"
            />

            <div tabindex="0" v-on:click="onClickHint('yes')" class="card-hint" style="right:-27px;">
                <!--<i class="pi pi-check"></i> Yes-->
                <Button label="Yes" icon="pi pi-check" class="p-button-rounded p-button-outlined mr-2 mb-2"></Button>
            </div>
            <div v-on:click="onClickHint('no')" class="card-hint" style="left:-20px;">
                <!--<i class="pi pi-times"></i> No-->
                <Button label="No" icon="pi pi-times" class="p-button-rounded p-button-outlined mr-2 mb-2"></Button>
            </div>
            <div v-on:click="onClickHint('skip')" class="card-hint hint-middle" style="top:-1%;left: calc(50% - 40px);">
                <!--<i class="pi pi-arrow-right"></i> Skip one-->
                <Button label="Skip" icon="pi pi-arrow-right" class="p-button-rounded p-button-outlined mr-2 mb-2"></Button>
            </div>
        </div>
        <div class="justify-center">
            <Button label="Skip all remaining questions." icon="pi pi-step-forward" class="w-8" v-on:click="skip"></Button>
        </div>
    </div>
</template>

<script>
import C_QCardsStack from './../C_QCardsStack.vue';
import VueCountdown from '@chenfengyuan/vue-countdown';
import UserService from '../../service/UserService';

export default {
    props: {
        external: {
            default: false
        }
    },
    data() {
        return {
            topareas: "",
            scrollingBlocked: false,
            computedVector: [2.5, 2.5, 2.5, 2.5, 2.5, 2.5],
            radarOptions: {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    r: {
                        beginAtZero: true,
                        angleLines: {
                            display: false
                        },
                        max: 5,
                        min: 0,
                        ticks: {
                            stepSize: 1
                        }
                    }
                }
            },
            radarData: {
                labels: ['Grow', 'Lead', 'Balance', 'Change', 'Escape', 'Reflect'],
                datasets: [
                    {
                        label: '',
                        backgroundColor: 'rgba(179,181,198,0.2)',
                        borderColor: 'rgba(179,181,198,1)',
                        pointBackgroundColor: 'rgba(179,181,198,1)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(179,181,198,1)',
                        data: [2.5, 2.5, 2.5, 2.5, 2.5, 2.5]
                    }
                ]
            },
            skipped: false,
            currentVector: [],
            visibleCards: [
                ["Are you up for promotion in the next 3-6 months?", 1],
                ["Are you actively learning (or planning to learn) a new skill?", 2],
                ["Do you need help to better negotiate your compensation?", 3],
                ["Do you have some specific soft skills (e.g. listening, convincing) that you want to develop?", 4],
                ["Do you have one or several mentors who help you develop your career?", 5],
                ["Are you managing a team?", 6],
                ["Are you comfortable managing a team?", 7],
                ["Are you comfortable asserting yourself in a professional context (with colleagues, clients, bosses)?", 8],
                ["Do you inspire your teamates and/or direct reports (if you're a manager)?", 9],
                ["Do you feel you are taken seriously by your peers?", 10],
                ["Do you feel you need to bring your emotions under control at the workplace?", 11],
                ["Do you feel free to express your emotions at work?", 12],
                ["Do you have a good work-life balance?", 13],
                ["Do you need to balance a family life with your work life?", 14],
                ["Do you struggle with social interactions in remote work?", 15],
                ["Do you consider switching careers?", 16],
                ["Are you planning to work abroad?", 17],
                ["Do you want to switch job functionality (e.g. an analytical job to a consumer-facing one, etc)?", 18],
                ["Do you consider pursuing your studies after having worked for a while (MBA, graduate studies)?", 19],
                ["Are you faced with major professional or personal changes at the moment?", 20],
                ["Do you feel that you are stuck in your current job?", 21],
                ["Do you have a bad relationship with your boss / main client?", 22],
                ["Are you afraid / depressed to go work everyday?", 23],
                ["Do you have a good culture at your workplace?", 24],
                ["Do you feel unappreciated at your workplace?", 25],
                ["Do you have an idea of what your core values are?", 26],
                ["Are you a completely different person in private and at work?", 27],
                ["Do you struggle with maintaining a 'public persona'?", 28],
                ["Do you have some specific things that you know you enjoy at work?", 29],
                ["Are you aware of some 'triggers' that you dislike at work?", 30]
            ],
            texts: {
                0: [
                    "You feel you're currently in the right place given your experience. You might want to learn new things or to improve your position, but this is not something critical either.",
                    "You want to continue developing new skills, moving to new positions, and improving your compensation...but this is not your priority.",
                    "Growth, in every sense of the term (skills, position, compensation), is your priority."
                ],
                1: [
                    "You know how to exert the right level of leadership required of your position. As such, leadership is not a critical growth area for you at the moment.",
                    "You are used to leadership, but there are still aspects that you want to master.",
                    "You feel that you need to develop your leadership and communication skills, as your scope of responsibilities is growing."
                ],
                2: [
                    "You have a fair balance between life and work and you manage your emotions fairly well.",
                    "Balancing work and life can be difficult at times, but you usually manage it, although it be a cause for stress.",
                    "You struggle with reconciling work with your personal and emotional life. Finding a better equilibrium is one of your priorities."
                ],
                3: [
                    "You don't feel the need for an immediate change; you are comfortable where you currently are, although you could always be open to interesting opportunities.",
                    "You would be open to some changes in your professional life, but this is not a critical need.",
                    "You are ready to embrace some major changes to your professionnal life. You might want to explore how to make them happen and how to manage their long-reaching consequences."
                ],
                4: [
                    "You don't face any immediate threats in your work environment and can serenely consider the future.",
                    "Your work environment could be more welcoming and conducive to your growth, but you do not feel that you are under an immediate threat.",
                    "You are facing a difficult situation at work, be it a toxic environment, a difficult boss or colleague, or a dead-end job. You are looking for a way out."
                ],
                5: [
                    "You know what your core values are, as well as what you like and dislike about work. You could explore your relationship to work with more nuance, but it is clear to you, at least in a broad sense.",
                    "You have a grasp on what your values, desires, and triggers are, but you might want to explore them further.",
                    "You want to reflect on your professional life in a broader sense: What makes you tick, what values animate you, what are the triggers that you need to avoid or manage?"
                ],
            },
            currTexts: [],
        };
    },
    components: {
        'C_QCardsStack': C_QCardsStack,
        'VueCountdown': VueCountdown
    },
    mounted() {
        this.currentVector = [];

        var ref = this;
        window.onkeydown = function(e) {
            if (ref.$route.name && typeof(ref.$route.name) !== "undefined" && ref.$route.name !== null) {
                console.log(ref.$route.name);
                if (ref.$route.name.includes('questionnaire') || ref.$route.name.includes('signup')) {
                    switch (e.keyCode) {
                        case 37:
                            e.preventDefault();
                            ref.onClickHint('no');
                            break;
                        case 38:
                            e.preventDefault();
                            ref.onClickHint('skip');
                            break;
                        case 39:
                            e.preventDefault();
                            ref.onClickHint('yes');
                            break;
                        // case 40:
                        //     e.preventDefault();
                        //     ref.onClickHint('skip');
                        //     break;
                        case 32: // spacebar
                            e.preventDefault();
                            ref.onClickHint('skip');
                            break;
                    }
                }
            }

        };
    },
    userService: null,
    created() {
        this.userService = new UserService();
    },
    methods: {
        disableOverflow() {
            this.scrollingBlocked = true;
            document.querySelector("html").style.overflow = 'hidden';
        },
        enableOverflow() {
            var ref = this;
            this.scrollingBlocked = false;
            setTimeout(() => {
                if (!ref.scrollingBlocked) {
                    document.querySelector("html").style.overflow = 'auto';
                }
            }, 1000);
        },
        update() {
            return this.userService.updateUser(
                {
                    "vector": this.currentVector
                }
            );
        },
        handleCardAccepted() {
            this.currentVector.push(1);
            this.compute();
            this.update().then(() => console.log('Updated A'));
        },
        handleCardRejected() {
            this.currentVector.push(0);
            this.compute();
            this.update().then(() => console.log('Updated R'));
        },
        handleCardSkipped() {
            this.currentVector.push(0.5);
            this.compute();
            this.update().then(() => console.log('Updated S'));
        },
        removeCardFromDeck() {
            this.visibleCards.shift();
        },
        submit(event) {
            if (this.external) {
                this.update().then(() => {
                    this.$toast.add({severity:'info', summary: 'Successfully saved.', life: 3000});
                });
            } else {
                this.update().then(() => this.$router.push("/signup/completion"));
            }
        },
        skip(event) {
            for (var i = 0; i < 30 - this.currentVector.length;) {
                this.currentVector.push(0.5);
            }
            this.compute();
            this.skipped = true;
            this.update();
        },
        compute() {
            var baseVector = [1, 1, 1, 1, 0, 1, 0, 0, 0, 0, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1, 1, 0, 0];
            this.computedVector = [];
            this.currTexts = [];
            this.radarData['datasets'][0]['data'] = [];
            var m = 0;
            for (var i = 0; i < this.currentVector.length; i += 5) {
                var currScore = 0;
                for (var j = 0; j < 5; j++) {
                    if (baseVector[i + j] == 1) {
                        currScore += this.currentVector[i + j];
                    } else {
                        currScore += (1 - this.currentVector[i + j]);
                    }
                }
                this.radarData['datasets'][0]['data'].push(currScore);
                this.computedVector.push(currScore);
                if (currScore <= 2) {
                    this.currTexts.push(this.texts[m][0]);
                } else if (currScore > 2 && currScore < 4) {
                    this.currTexts.push(this.texts[m][1]);
                } else if (currScore >= 4) {
                    this.currTexts.push(this.texts[m][2]);
                }
                m += 1;
            }

            var indices = Array.from(this.computedVector.keys());
            indices.sort((a, b) => {
                // console.log(a, b);
                // console.log(this.computedVector[a], this.computedVector[b]);
                var v1 = this.computedVector[a];
                var v2 = this.computedVector[b];
                if (v1 === v2)
                     return 0;

                return v1 > v2 ? -1 : 1;
            });
            console.log(indices);

            var sortedLabels = indices.map(i => this.radarData['labels'][i]);
            var sortedVector = indices.map(i => this.computedVector[i]);

            if (sortedVector[1] == sortedVector[2]) {
                sortedLabels = sortedLabels.slice(0, 3);
                this.topareas = sortedLabels[0] + ", " + sortedLabels[1] + " and " + sortedLabels[2];
            } else {
                sortedLabels = sortedLabels.slice(0, 2);
                this.topareas = sortedLabels[0] + " and " + sortedLabels[1];
            }

            console.log(this.radarData['datasets'][0]['data']);
        },
        onClickHint(type='skip') {
            console.log(this.$refs.cardstack);
            if (type == "skip") {
                this.$refs.cardstack.playCardFromOutside("cardSkipped");
            } else if (type == "yes") {
                this.$refs.cardstack.playCardFromOutside("cardAccepted");
            } else if (type == "no") {
                this.$refs.cardstack.playCardFromOutside("cardRejected");
            }
        }
    }
}
</script>
<style lang="scss">
@import "../../assets/styles/cards/mixins.scss";

.cards-main {
    height: 500px;
}
.card-hint {
    position: absolute;
    top: 45%;
    color: gray;
    cursor: pointer;
}
.q-main {
    flex-direction: column;
    align-items: center;
}
@media (max-width: 450px) {
    .hint-middle {
        left:37%!important;
        left: calc(50% - 40px)!important;
    }
    .p-chart {
        width: 100%!important;
    }
    .cardTitle {
        font-size: 1.35em!important;
    }
}
</style>
