<template>
  <div class="cards">
    <GameCard
      ref="gamecard"
      v-for="(card, index) in cards"
      :key="card"
      :card="card"
      :cardidx="index"
      :is-current="index === 0"
      @cardAccepted="$emit('cardAccepted');"
      @cardRejected="$emit('cardRejected');"
      @cardSkipped="$emit('cardSkipped');"
      @hideCard="$emit('hideCard');"
      @touchstart="disableOverflow"
      @touchend="enableOverflow"
    />
  </div>
</template>

<script>
import GameCard from "@/components/C_QCard";

export default {
    components: {
        GameCard
    },

    data() {
        return {
            scrollingBlocked: false
        }
    },

    methods: {
        disableOverflow() {
            this.scrollingBlocked = true;
            document.querySelector("html").style.overflow = 'hidden';
        },
        enableOverflow() {
            var ref = this;
            this.scrollingBlocked = false;
            setTimeout(() => {
                if (!ref.scrollingBlocked) {
                    document.querySelector("html").style.overflow = 'auto';
                }
            }, 500);
        },
        playCardFromOutside(str) {
            console.log(str);
            console.log(this.$refs);
            this.$refs.gamecard[0].playCard(str);
        }
    },

  props: {
    cards: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.cards {
  position: relative;
  display: flex;
  margin: 50px;
  width: 300px;
}
</style>
